var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"modalVerComprovante","tabindex":"-1","aria-labelledby":"modalVerComprovanteLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content border-0 bg-transparent"},[_c('div',{staticClass:"modal-body p-0"},[_c('div',{staticClass:"card w-max-content mx-auto"},[_c('div',{staticClass:"card-body p-0"},[(_vm.tipo == 'SANGRIA')?_c('impressaoSangria',{attrs:{"id":"sangria","sangria":_vm.comprovante}}):(_vm.tipo == 'VENDA_NFCE')?_c('impressaoVenda',{attrs:{"id":"venda","venda":_vm.comprovante}}):(_vm.comprovante && _vm.tipo == 'PEDIDO')?_c('impressaoA4',{attrs:{"id":"pedido","titulo":"Pedido","data":_vm.comprovante.data,"cabecalho":{
                     'Nome': _vm.comprovante.nome, 
                     'Loja': _vm.comprovante.nomeLoja +' / Nº '+ _vm.comprovante.numeroLoja, 
                     'CPF/CNPJ Fornecedor': _vm.comprovante.cpfCnpjFornecedor, 
                     },"itensKey":{'nome': 'Descrição', 'quantidade': 'Qtd.', 'custo': 'Custo'},"itens":_vm.comprovante.produtos,"total":_vm.comprovante.valorTotal}}):(_vm.comprovante != null)?_c('impressaoA4',{attrs:{"id":"entradaSaida","titulo":"Entrada/Saída","data":_vm.comprovante.data == null ? null : String(_vm.comprovante.data.dayOfMonth).padStart(2, '0') +'/'+ String(_vm.comprovante.data.monthValue).padStart(2, '0') +'/'+ _vm.comprovante.data.year +' '+
                        String(_vm.comprovante.data.hour).padStart(2, '0') +':'+ String(_vm.comprovante.data.minute).padStart(2, '0'),"cabecalho":{
                        'Tipo': _vm.comprovante.isEntrada ? 'Entrada' : 'Saída', 
                        'Operação': _vm.comprovante.motivo, 
                        'Loja destinatário': _vm.comprovante.nomeLoja +' / Nº '+ _vm.comprovante.numeroLoja, 
                        },"itensKey":{'nomeProduto': 'Descrição', 'qtd': 'Qtd.', 'valor': 'Valor'},"itens":_vm.comprovante.produtos,"total":_vm.comprovante.valorTotal}}):_vm._e()],1)])]),_c('div',{staticClass:"modal-footer border-0 justify-content-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.imprimir}},[_vm._v("Imprimir")]),(_vm.tipo != 'PEDIDO' && _vm.tipo != 'SANGRIA' && _vm.tipo != 'VENDA_NFCE')?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar nota")]):_vm._e(),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":_vm.modalAnterior}},[_vm._v(_vm._s(_vm.modalAnterior == null ? 'Fechar' : 'Voltar'))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }