<!-- eslint-disable vue/require-v-for-key -->
<template>
	<div class="row align-items-start">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
			<div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
				<strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
				<span>Nesta tela, você pode criar novos pedidos de compra, especificando itens e quantidades. Integre seu pedido com o módulo fiscal para garantir a precisão das informações.</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
			</div>
		</div>

		<div class="nav nav-pills cards-150 cards-sm-200 mb-2" id="loja-tab" role="tablist" aria-orientation="vertical">
			<button v-for="(loja, index) in dadosUsuario.usuarioLojas" :key="index" class="nav-link cursor-auto mx-0" type="button" role="tab">
				<div class="form-check form-switch h-auto mb-0">
					<div class="row align-items-center">
						<div class="w-max-content"><input class="form-check-input mt-0" type="checkbox" :id="'loja'+ index" @click="toggleLoja(loja)" :checked="pesquisa.lojas.findIndex(l => l == loja.id) != -1"></div>
						<div class="col px-0">
							<label v-if="tempPedido.findIndex(p => p.idLoja == loja.id) != -1" class="form-check-label cursor-pointer" @click="abrirModal(loja.id)">
								<span class="limitador-1 mb-1 text-uppercase">{{ loja.nomeFantasia }}</span>
								<span class="d-block text-secondary">
									<span class="d-none d-sm-inline"><i class="far fa-tag font-9 me-1"></i> {{ loja.numeroLoja }}<small class="mx-2">|</small></span>
									<span><i class="far fa-clipboard color-theme font-10 me-1"></i> Ver pedido</span>
								</span>
							</label>
							<label v-else class="form-check-label cursor-pointer" :for="'loja'+ index">
								<span class="limitador-1 mb-1 text-uppercase">{{ loja.nomeFantasia }}</span>
								<span class="d-block text-secondary"><i class="far fa-tag font-9 me-1"></i> {{ loja.numeroLoja }}</span>
							</label>
						</div>
					</div>
				</div>
			</button>
			<button v-if="dadosUsuario.usuarioLojas == null || dadosUsuario.usuarioLojas.length == 0" class="nav-link cursor-auto mx-0" type="button" role="tab">
				<div class="form-check form-switch h-auto text-center mb-0 p-0">
					<i class="fal fa-store-slash font-35 text-secondary opacity-50 d-block mb-3"></i>
					<h4 class="font-11 mt-3 mb-0 text-secondary text-uppercase">Nenhuma loja encontrada</h4>
				</div>
			</button>
			<div class="card shadow-none d-none d-lg-flex" v-if="tempPedido.length > 0">
				<div class="card-body p-12">
					<p class="font-13 mb-0 text-center"><i class="far fa-clipboard color-theme font-12 me-1"></i> {{ tempPedido.length }} pedido(s) pendente(s)</p>
					<hr class="my-12">
					<button class="btn w-100 border-red text-red" @click="excluirTemp(null)"><i class="fal fa-times font-12 me-1"></i> Descartar tudo</button>
				</div>
			</div>
		</div>

		<div class="col-lg">
			<div class="row">
				<div id="search-nav" class="col-12 px-0 d-block btn-group dropup">
					<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
						<i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
					</button>
					<div class="card mb-2 dropdown-menu dropdown-menu-left">
						<div class="card-body pb-0">
							<div class="row">
								<div class="col-6 col-md-4 col-xl-2 mb-3">
									<label><i class="far fa-file color-theme font-11 me-1"></i> Categoria</label>
									<v-select name="Desktop" :options="listaCategorias" v-model="pesquisa.categoria" label="nome" :reduce="c => c.nome" placeholder="Todas as categorias" 
										@input="buscarSubcategorias(pesquisa.categoria)">
										<template #option="option"><img :src="option.banner == null ? '' : option.banner" @error="imageError" /> {{ option.nome }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Categorias" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.categoria" @change="buscarSubcategorias(pesquisa.categoria)">
										<option v-for="(option, index) in listaCategorias" :key="index" :value="option.nome">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-6 col-md-4 col-xl-2 mb-3">
									<label><i class="far fa-copy color-theme font-11 me-1"></i> Subcategoria</label>
									<v-select name="Desktop" :options="listaSubcategorias" v-model="pesquisa.subcategoria" placeholder="Todas as subcategorias">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-if="pesquisa.categoria">Utilize a tela "Categorias" para adicionar</small>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-else>Selecione uma categoria</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.subcategoria">
										<option v-for="(option, index) in listaSubcategorias" :key="index" :value="option">{{ option }}</option>
									</select>
								</div>
								<div class="col-6 col-md-4 col-xl-3 mb-3">
									<label><i class="far fa-truck color-theme font-11 me-1"></i> Fornecedor</label>
									<v-select label="nomeFantasia" :filterable="false" :options="listaFornecedores" v-model="pesquisa.fornecedor" @search="onBuscarFornecedores" :reduce="f => f.id" placeholder="Todos os fornecedores">
                              <template #option="option">
                                 <strong class="weight-500 text-wrap">{{ option.nomeFantasia }}</strong><br/>
                                 <small class="font-11 text-wrap"><i class="fal fa-address-card font-9 me-1"></i>{{ option.cpfCnpj || 'Desc.' }}</small>
                              </template>
                              <template #list-header><span class="d-block lh-sm my-1 opacity-90 font-11 text-center"><i class="far fa-search font-9 me-1"></i>Utilize o campo acima para buscar fornecedores</span></template>
                              <template v-slot:no-options><span class="d-block my-1">Sem resultados</span></template>
                           </v-select>
								</div>
								<div class="col-6 col-md-10 col-xl-4 mb-3">
									<label><i class="far fa-apple-alt color-theme font-12 me-1"></i> Nome/Código/Família</label>
									<input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarProdutos" placeholder="Busque por nome do produto, código ou família" />
								</div>
								<div class="col-12 col-md-2 col-xl-1 mb-3 align-self-end">
									<div class="dropdown dropstart text-end">
										<a class="dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">
											<i class='bx bx-dots-horizontal-rounded color-theme font-22'></i>
										</a>
										<ul class="dropdown-menu">
											<li class="dropdown-item pt-2 pb-12"><strong class="weight-500"><i class="far fa-search color-theme font-11 me-1"></i> Pesquisa</strong></li>
											<li class="dropdown-item">
												<div class="form-check form-switch mb-0">
													<input class="form-check-input" type="checkbox" id="estoqueMinimo" :checked="pesquisa.estoqueMinimo"
														@click="pesquisa.estoqueMinimo = !pesquisa.estoqueMinimo; pesquisa.retorno.resultado = [];">
													<label class="form-check-label m-0 ps-1 pt-1 font-13" for="estoqueMinimo">Apenas c/ estoque mínimo</label>
												</div>
											</li>
										</ul>
									</div>
									<button type="button" class="btn btn-primary w-100" @click="buscarProdutos"><i class="fas fa-search font-13"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 px-0" v-if="pesquisa.retorno.resultado.length > 0">
					<div class="card mb-0">
						<div class="card-body p-12">
							<div class="row">
								<div class="col mb-2 align-self-center">
									<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
								</div>
								<div class="w-max-content mb-2">
									<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
								</div>
							</div>
							<div class="row">
								<produto v-for="(produto, index) in pesquisa.retorno.resultado" :key="index" :produto="produto" :tempPedido="tempPedido" :index="index" 
									@addTemp="salvarProdutoTemp($event)" @removeTemp="removeProdutoTemp($event)" @movimentacoes="buscarEntradasSaidas($event)" @pedidos="buscarPedidosProduto($event, false)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalPedido -->
      <div class="modal fade" id="modalPedido" tabindex="-1" aria-labelledby="modalPedidoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPedidoLabel">Pedido pendente ({{ modal.nomeLoja }} - nº {{ modal.numeroLoja }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="card">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-6"><strong class="subtitle font-13 me-1">Valor do pedido</strong></div>
											<div class="col-6 text-end">
												<span class="font-14"><small>R$ </small>{{ parseFloat(modal.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
											</div>
											<div class="col-12"><hr></div>
											<div class="col-12">
												<label><i class="far fa-truck color-theme font-10 me-1"></i> Fornecedor *</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="modal.searchFornecedor ? 'fa-undo' : 'fa-search'" @click="modal.searchFornecedor = !modal.searchFornecedor"
															:title="modal.searchFornecedor ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!modal.searchFornecedor" @click="modal.searchFornecedor = true;" :value="modal.cpfCnpjFornecedor" title="Buscar emitente" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção" />
													<autoCompleteDestinatario v-else class="col" :tipo="'Fornecedor'" @submit="setFornecedorTemp($event)" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<produtoLoja v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" 
								@salvar="salvarProdutoTemp($event)" @removeTemp="removeProdutoTemp($event)" />

							<div class="col-12 my-5 text-center" v-if="modal.produtos.length == 0">
								<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarPedido">Salvar</button>
                  <button type="button" class="btn btn-danger" @click="excluirTemp(modal)">Descartar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalEntradasSaidas -->
      <div class="modal fade" id="modalEntradasSaidas" tabindex="-1" aria-labelledby="modalEntradasSaidasLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEntradasSaidasLabel">{{ entradasSaidas.isEntrada ? 'Entradas' : 'Saídas' }} de "{{ entradasSaidas.nomeProduto }}"</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <div class="col ps-0">
                        <div class="card mb-2 shadow-sm">
                           <div class="card-body p-12">
                              <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                              <datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="entradasSaidas.dataInicio" @input="buscarEntradasSaidas"></datePicker>
                           </div>
                        </div>
                     </div>
                     <div class="w-max-content pe-0 align-self-end">
                        <ul class="nav nav-header flex-column mb-2 pb-0">
                           <li class="nav-item mb-2">
                              <a href="javascript:;" class="nav-link py-1 px-12 mx-0 lh-sm flex-grow-1 w-auto shadow-sm" @click="buscarEntradasSaidas(null, null)" :class="entradasSaidas.isEntrada ? 'active' : ''">
                                 <span class="h-auto lh-base"><i class="bx bx-package"></i>Entradas</span>
                              </a>
                           </li>
                           <li class="nav-item">
                              <a href="javascript:;" class="nav-link py-1 px-12 mx-0 lh-sm flex-grow-1 w-auto shadow-sm" @click="buscarEntradasSaidas(null, null)" :class="entradasSaidas.isEntrada ? '' : 'active'">
                                 <span class="h-auto lh-base"><i class="bx bx-cart"></i>Saídas</span>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div class="card shadow-sm">
                     <div class="card-body p-12">
                        <table class="table table-bordered mb-1 font-12">
                           <thead>
                              <tr>
                                 <td class="col-4 text-center align-middle">
                                    <a href="javascript:;" class="color-theme d-flex align-items-center justify-content-center" @click="buscarEntradasSaidas(null, 7)">
                                       <i class="far fa-search color-theme font-10 me-2"></i><span class="d-block text-start">Buscar 7d anteriores</span>
                                    </a>
                                 </td>
                                 <td class="col-4 text-center align-middle">
                                    <a href="javascript:;" class="color-theme d-flex align-items-center justify-content-center" @click="buscarEntradasSaidas(null, 30)">
                                       <i class="far fa-search color-theme font-10 me-2"></i><span class="d-block text-start">Buscar mês anterior</span>
                                    </a>
                                 </td>
                                 <td class="col-4 text-center align-middle">
                                    <a href="javascript:;" class="color-theme d-flex align-items-center justify-content-center" @click="buscarEntradasSaidas(null, 365)">
                                       <i class="far fa-search color-theme font-10 me-2"></i><span class="d-block text-start">Buscar ano anterior</span>
                                    </a>
                                 </td>
                              </tr>
                           </thead>
                        </table>
                        <table class="table table-bordered mb-0 font-12">
                           <thead>
                              <tr>
                                 <th scope="col" class="weight-500 text-center text-sm-start" style="min-width: 100px;">Período</th>
                                 <th scope="col" class="weight-500 text-center" style="min-width: 80px;">Quant.</th>
                                 <th scope="col" class="weight-500 text-center" style="min-width: 80px;">Valor</th>
                                 <th scope="col" style="width: 80px;"></th>
                              </tr>
                           </thead>
                           <tbody>
                              <template v-for="resultado in entradasSaidas.resultado">
                                 <tr>
                                    <td>
                                       <i class="far fa-calendar color-theme me-1"></i>
                                       <span> {{ String(resultado.dataInicio.dayOfMonth).padStart(2, '0') +'/'+ String(resultado.dataInicio.monthValue).padStart(2, '0') +'/'+ resultado.dataInicio.year }} </span>
                                       <span class="d-none d-sm-inline">~</span>
                                       <span> {{ String(resultado.dataFim.dayOfMonth).padStart(2, '0') +'/'+ String(resultado.dataFim.monthValue).padStart(2, '0') +'/'+ resultado.dataFim.year }} </span>
                                    </td>
                                    <td class="text-center weight-500">
                                       <span class="color-theme">{{ resultado.lojas.length == 0 ? 0 : resultado.somaQuantidade ? parseFloat(resultado.somaQuantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) : 0 }}</span>
                                    </td>
                                    <td class="text-center weight-500">
                                       <span class="color-theme">{{ resultado.lojas.length == 0 ? '-' : resultado.mediaValorUnitario ? parseFloat(resultado.mediaValorUnitario).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) : '-' }}</span>
                                    </td>
                                    <td class="text-center">
                                       <a href="javascript:;" class="color-theme weight-500" @click="addTemp(resultado.lojas)"><i class="far fa-tasks font-11"></i><span class="ms-1 d-none d-sm-inline"> Incluir</span></a>
                                    </td>
                                 </tr>
                                 <template v-for="loja in resultado.lojas">
                                    <template v-for="(historico, index) in loja.historico">
                                       <tr>
                                          <td class="text-end align-middle" :rowspan="loja.historico.length" v-if="index == 0">
                                             <span> {{ loja.nomeLoja || 'Loja desconhecida' }}</span>
                                             <span v-if="loja.numeroLoja" class="d-none d-md-inline"><small class="mx-2">|</small><i class="far fa-tag font-9 me-1"></i> {{ loja.numeroLoja }}</span>
                                          </td>
                                          <td class="text-center align-middle">{{ historico.quantidade ? parseFloat(historico.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) : 0 }}</td>
                                          <td class="text-center align-middle">{{ historico.valorUnitario ? parseFloat(historico.valorUnitario).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) : '-' }}</td>
                                          <td class="text-center align-middle" :rowspan="loja.historico.length" v-if="index == 0">
                                             <a href="javascript:;" class="color-theme" @click="addTemp(loja)"><i class="far fa-plus"></i></a>
                                          </td>
                                       </tr>
                                    </template>
                                 </template>
                              </template>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPedidos -->
      <div class="modal fade" id="modalPedidos" tabindex="-1" aria-labelledby="modalPedidosLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPedidosLabel">Pedidos ({{ pedidos.nomeProduto }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="pedidos.resultado.length > 0">
                  <div class="row">
                     <pedido v-for="(pedido, index) in pedidos.resultado" :key="index" :pedido="pedido" :index="index" :showEdit="false" @visualizar="verPedido($event)" />
                     
                     <div class="col-12 mt-3 mb-2 text-center" v-if="pedidos.resultado.length % 30 == 0">
                        <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarPedidosProduto(null, false)">Ver mais</button>
                     </div>
                  </div>
               </div>
               <div class="modal-body my-5 text-center" v-else>
                  <i class="fal fa-print-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
                  <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum pedido encontrado</h4>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <modalComprovante :modalAnterior="'#modalPedidos'" tipo="PEDIDO" :comprovante="pedidos.comprovante" />
		<impressao id="impressao" class="d-none" titulo="Pedido" :data="new Date()" :cabecalho="{
			'Nome': modal.nome, 
			'Loja': modal.nomeLoja +' / Nº '+ modal.numeroLoja, 
			'CPF/CNPJ Fornecedor': modal.cpfCnpjFornecedor, 
			}" :itensKey="{'nome': 'Descrição', 'pedidoSugerido': 'Qtd.', 'custo': 'Custo'}" :itens="modal.produtos" :total="modal.valorTotal" />
	</div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import pedido from '@/components/compras/pedidosConsulta/Pedido.vue'
import produto from '@/components/compras/pedidosCriacao/Produto.vue'
import modalComprovante from '@/components/modelos/ModalComprovante.vue'
import produtoLoja from '@/components/compras/pedidosCriacao/ProdutoLoja.vue'
import autoCompleteDestinatario from '@/components/modelos/AutoCompleteDestinatario.vue'

export default {
	name: 'Criação_pedidos',
	data: function () {
		return {
			pesquisa: {
				'categoria': null, 'subcategoria': null, 'nome': '', 'fornecedor': null, 'lojas': [], 'estoqueMinimo': false,
				'retorno': {'categoria': null, 'subcategoria': null, 'nome': '', 'fornecedor': null, 'lojas': [], 'resultado': []}
			},
			listaSubcategorias: [],
			listaFornecedores: [],
			tempPedido: [],
			modal: {'nome': '', 'cpfCnpjFornecedor': '', 'idLoja': null, 'nomeLoja': null, 'numeroLoja': null, 'produtos': [], 'valorTotal': '0.00', 'searchFornecedor': false},
         entradasSaidas: {'idProduto': null, 'nomeProduto': null, 'isEntrada': false, 'dataInicio': [], 'data': [], 'resultado': []},
         pedidos: {'idProduto': null, 'nomeProduto': null, 'pagina': null, 'resultado': [], 'comprovante': null}
		}
	},
	computed: {
      ... mapState({
         listaCategorias: state => state.listaCategorias,
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
			this.buscarTemp()
		},
		'modal.produtos': {
			handler: function (newVal) {
				this.modal.valorTotal = newVal.reduce((total, p) => total + ((/^[0-9.]+$/.test(p.pedidoSugerido) ? parseFloat(p.pedidoSugerido) : 0) * (/^[0-9.]+$/.test(p.custo) ? parseFloat(p.custo) : 0)), 0)
			},
			deep: true
      }
	},
	components: {
		produto, produtoLoja, impressao, autoCompleteDestinatario, pedido, modalComprovante
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Pedidos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos':  ['id', 'nome', 'marca', 'categoria', 'subcategoria', 'familia', 'unidadeMedida'], 
				'lista': {'nome': 'lojas', 'campos': ['nomeFantasia', 'numeroLoja', 'custo', 'preco', 'estoqueMinimo', 'estoqueAtual', 'pedidoSugerido']}
			})
		},
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		abrirModal : function (idLoja) {
			let loja = JSON.parse(JSON.stringify(this.tempPedido.find(t => t.idLoja == idLoja)))
			this.modal = {
				'searchFornecedor': false,
				'nome': '', 
				'cpfCnpjFornecedor': loja.cpfCnpjFornecedor, 
				'idLoja': loja.idLoja, 
				'nomeLoja': loja.nomeLoja, 
				'numeroLoja': loja.numeroLoja, 
				'valorTotal': loja.produtos.reduce((total, p) => total + ((/^[0-9.]+$/.test(p.pedidoSugerido) ? parseFloat(p.pedidoSugerido) : 0) * (/^[0-9.]+$/.test(p.custo) ? parseFloat(p.custo) : 0)), 0),
				'produtos': loja.produtos.map(p => {
					return {
						...p,
						pedidoSugerido: p.isDecimal ? parseFloat(p.pedidoSugerido).toFixed(3) : parseInt(p.pedidoSugerido)
					};
				})
			}

			$("#modalPedido").modal("show")
		},
		toggleLoja : function (loja) {
			let index = this.pesquisa.lojas.findIndex(l => l == loja.id)

			if (index != -1) {
				this.pesquisa.lojas.splice(index, 1)
			} else {
				this.pesquisa.lojas.push(loja.id)
			}

			this.buscarProdutos()
		},
		buscarTemp : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.tempPedido, this.$options.data.apply(this).tempPedido)

			if (this.pesquisa.lojas.length == 0 && this.dadosUsuario.usuarioLojas.length > 0) {
				this.pesquisa.lojas.push(this.dadosUsuario.usuarioLojas[0].id)
			}

			this.$axios({
				method: 'get',
				url: this.urlRest +'compras/getPedidoTemp'

			}).then(response => {
				this.tempPedido = response.data
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarProdutos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/searchProdutos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarSubcategorias : function (categoria) {
			if (categoria == null) {
				this.pesquisa.subcategoria = null
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$store.dispatch('getSubcategorias', categoria).then((data) => {
				this.listaSubcategorias = data

				if (data.length > 0) {
					this.pesquisa.subcategoria = data[0]
				}
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      onBuscarFornecedores : function (valor, loading) {
         if (valor.length >= 3) {
            loading(true)
            this.buscarFornecedores(loading, valor, this)
         }
      },
      buscarFornecedores : debounce((loading, valor, vm) => {
         vm.listaFornecedores = []

         vm.$axios({
				method: 'post',
				url: vm.urlRest +'fiscal/searchFornecedores',
				headers: {'Content-Type': 'application/json'},
				data: {'nome': valor}

			}).then(response => {
				vm.listaFornecedores = response.data.resultado

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						vm.$store.dispatch('deslogar')

						vm.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						vm.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				loading(false)
			});
		}, 1000),
      buscarEntradasSaidas : function (event, dias) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         if (!event && dias) { // Ao clicar nos botoes
            this.entradasSaidas.data = this.entradasSaidas.data.map(d => 
               new Date(d.setFullYear(d.getFullYear() - (dias == 365 ? 1 : 0), d.getMonth() - (dias == 30 ? 1 : 0), d.getDate() - (dias != 30 && dias != 365 ? dias : 0)))
            );
         } else if (!event && !dias) { // Ao clicar nos switch
            this.entradasSaidas.resultado = []
            this.entradasSaidas.isEntrada = !this.entradasSaidas.isEntrada

         } else if (!Array.isArray(event)) { // Ao abrir modal
            this.entradasSaidas = {
               'idProduto': event.idProduto, 
               'nomeProduto': event.nomeProduto, 
               'isEntrada': false, 
               'data': [new Date(Date.now() - 7 * 864e5), new Date()], 
               'dataInicio': [new Date(Date.now() - 7 * 864e5), new Date()], 
               'resultado': []
            }
         } else { // Ao usar datepicker
            this.entradasSaidas.resultado = []
            this.entradasSaidas.data = event
         }
			
			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/getEntradasSaidasAgrupadas',
            headers: {'Content-Type': 'application/json'},
            data: {...this.entradasSaidas, ...{'lojas': this.pesquisa.lojas}}

			}).then(response => {
				$('#modalEntradasSaidas').modal('show')
				this.entradasSaidas.resultado.push(response.data)
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      buscarPedidosProduto : function (event) {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.pedidos = {
				'idProduto': event != null ? event.idProduto : this.pedidos.idProduto, 
				'nomeProduto': event != null ? event.nomeProduto : this.pedidos.nomeProduto, 
				'pagina': event != null ? 0 : this.pedidos.pagina,
				'resultado': event != null ? [] : this.pedidos.resultado,
				'comprovante': null
			}

			$('#modalPedidos').modal('show')

         this.$axios({
            method: 'post',
            url: this.urlRest +'compras/getPedidosProduto',
				headers: {'Content-Type': 'application/json'},
				data: this.pedidos

         }).then(response => {
				this.pedidos.pagina++;
				this.pedidos.resultado.push(... response.data)

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      verPedido : function (pedido) {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'compras/getPedido',
				params: {
					id: pedido.id
				}
			}).then(response => {
				this.pedidos.comprovante = response.data
				$('#modalPedidos').modal('hide')
				$('#modalVerComprovante').modal('show')

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      addTemp : function (event) {
         if (Array.isArray(event)) { // Se clicou em incluir tudo
            event.forEach(e => this.salvarProdutoTemp({
               "id": this.entradasSaidas.idProduto, 
               "idLoja": e.idLoja, 
               "pedidoSugerido": e.historico.map(p => p.quantidade).reduce((total, qtd) => total += qtd, 0), 
               "custo": e.historico.map(p => p.valorUnitario).reduce((total, valor) => total += valor, 0) / e.historico.length
            }));
         } else { // Se clicou na loja
            this.salvarProdutoTemp({
               "id": this.entradasSaidas.idProduto, 
               "idLoja": event.idLoja, 
               "pedidoSugerido": event.historico.map(p => p.quantidade).reduce((total, qtd) => total += qtd, 0), 
               "custo": event.historico.map(p => p.valorUnitario).reduce((total, valor) => total += valor, 0) / event.historico.length
            })
         }

         $('#modalEntradasSaidas').modal('hide')
      },
		salvarProdutoTemp : function (produtoLoja) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/savePedidoProdutoTemp',
				headers: {'Content-Type': 'application/json'},
				data: produtoLoja

			}).then(() => {
				this.buscarTemp()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		removeProdutoTemp : function (produtoLoja) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/deletePedidoProdutoTemp',
				headers: {'Content-Type': 'application/json'},
				data: {'idLoja': produtoLoja.idLoja, 'idProduto': produtoLoja.idProduto}

			}).then(() => {
				this.buscarTemp()
				let index = this.modal.produtos.findIndex(p => p.id == produtoLoja.idProduto)

				if (index != -1) {
					this.modal.produtos.splice(index, 1)
				}

				if (this.modal.produtos.length == 0) {
					$("#modalPedido").modal("hide")
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setFornecedorTemp : function (fornecedor) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal.cpfCnpjFornecedor = fornecedor.cpfCnpj;
			this.modal.searchFornecedor = false;

			this.$axios({
				method: 'post',
				url: this.urlRest +'compras/savePedidoTemp',
				headers: {'Content-Type': 'application/json'},
				data: {"idLoja": this.modal.idLoja, "cpfCnpjFornecedor": fornecedor.cpfCnpj}

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		excluirTemp : function (loja) {
			Swal.fire({
            icon: 'warning',
            title: 'Descartar pedido?',
            text: (loja == null ? 'Todos os pedidos pendentes serão descartados.' : (loja.nomeLoja +' - nº '+ loja.numeroLoja)),
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'compras/deletePedidoTemp',
						params: {
							idLoja: loja == null ? null : loja.idLoja
						}
					}).then(() => {
						this.buscarTemp()
						$("#modalPedido").modal("hide")
		
						this.$toast.fire({
							icon: 'success',
							title: (loja == null ? 'Pedidos descartados!' : 'Pedido descartado!')
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		salvarPedido : function () {
			if (this.modal.cpfCnpjFornecedor == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Informe um fornecedor!'
				});

				return
			}

			$("#modalPedido").modal("hide")

			Swal.fire({
            icon: 'warning',
            title: 'Salvar pedido?',
            html: (this.modal.nomeLoja +' - nº '+ this.modal.numeroLoja) +'<span class="d-block font-16 mt-2">Informe o nome do pedido (Mínimo de 5 caracteres)</span>',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
				input: 'text',
				inputPlaceholder: 'Nome do pedido',
            inputValidator: (value) => {
               if (String(value).trim().length < 5) {
                  return 'Nome inválido!'
               }
            }				
         }).then((result) => {
            if (result.isConfirmed) {
					this.modal.nome = JSON.parse(JSON.stringify(result.value))
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'compras/savePedido',
						headers: {'Content-Type': 'application/json'},
						data: this.modal

					}).then(() => {
						Swal.fire({
							icon: 'success',
							title: 'Pedido salvo!',
							text: 'Imprimir comprovante?',
							showCancelButton: true,
							confirmButtonText: 'Sim',
							cancelButtonText: `Não`
							
						}).then((result) => {
							setTimeout(() => this.buscarTemp(), 500);

							if (result.isConfirmed) {
								setTimeout(() => this.$htmlToPaper('impressao', {styles: ['/comprovanteA4.css']}), 250);
							}
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		}
	},
	mounted() {
      this.$store.dispatch('getCategorias')
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
		this.buscarTemp()
	}
}

</script>

<style scoped>

.form-check.form-switch {
	min-height: auto;
}

.dropdown.dropstart {
	line-height: 0.8;
}

.dropdown .form-check-label {
	font-weight: 400 !important;
   text-transform: none !important;
}

#loja-tab .nav-link {
   line-height: 1;
   padding: 14px;
   text-align: start;
   font-weight: 500;
   font-size: 12px;
   transition: none !important;
	width: auto;
}

#loja-tab .nav-link * {
   transition: none !important;
}

@media (min-width: 992px) {
   #loja-tab ~ .col-lg {
      width: calc(100% - 225px);
   }

   #loja-tab {
      width: 225px;
   }
}

</style>